import {isLocal} from '@job-ish/utilities/env';

import type {Price, Subscription} from '@job-ish/database/types';
import type {Database} from '@job-ish/database/types/supabase';
import type {SupabaseClient} from '@supabase/supabase-js';

export const fetchPrices = async (supabase: SupabaseClient<Database>) =>
	supabase.from('prices').select('*, products(*)').filter('unit_amount', 'gt', 0).returns<Price[]>();

export const fetchSubscription = async (supabase: SupabaseClient<Database>) =>
	isLocal
		? ({data: {status: 'active'}} as {data: Subscription})
		: supabase.from('subscriptions').select('*, price(*, product(*))').maybeSingle<Subscription>();
