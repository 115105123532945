import {useCallback} from 'react';
import {Badge} from '@job-ish/ui/components';
import {formatCurrency} from '@job-ish/utilities/currency';
import {useLocale} from '@react-aria/i18n';
import {IconCashBanknote, IconHomeSignal, IconMapPin} from '@tabler/icons-react';

import type {Job} from '@job-ish/database/types';
import type {SalaryCurrencyEnum} from '@job-ish/job-data-api/client/job-data-api';

type PreviewProps = {
	job: Pick<Job, 'location' | 'remote' | 'min_salary' | 'max_salary' | 'min_hourly_rate' | 'max_hourly_rate'>;
	currency?: SalaryCurrencyEnum;
};

export const Preview = ({job, currency = 'USD'}: PreviewProps) => {
	const {locale} = useLocale();

	const getFormattedRange = useCallback(
		(min: number | null, max: number | null) => {
			if (min === max && min && !Number.isNaN(min)) {
				return formatCurrency(min, {locale, currency});
			}

			if (min && !max) {
				return `${formatCurrency(min, {locale, currency})}+`;
			}

			if (!min && max) {
				return `Up to ${formatCurrency(max, {locale, currency})}`;
			}

			if (min && max) {
				return `${formatCurrency(min, {locale, currency})} to ${formatCurrency(max, {locale, currency})}`;
			}
		},
		[locale, currency],
	);

	return (
		<div className="flex max-w-full flex-wrap items-center gap-1 [&&>div]:max-w-full">
			{job.location && (
				<Badge size="sm">
					<IconMapPin className="h-3 w-3 shrink-0" />
					<div className="truncate">{job.location}</div>
				</Badge>
			)}
			{job.remote === 'true' && (
				<Badge size="sm">
					<IconHomeSignal className="h-3 w-3 shrink-0" />
					<div className="truncate">Remote</div>
				</Badge>
			)}
			{(!!job.min_salary || !!job.max_salary) && (
				<Badge size="sm">
					<IconCashBanknote className="h-3 w-3 shrink-0" />
					<div className="truncate">{getFormattedRange(job.min_salary, job.max_salary)}</div>
				</Badge>
			)}
			{(!!job.min_hourly_rate || !!job.max_hourly_rate) && (
				<Badge size="sm">
					<IconCashBanknote className="h-3 w-3 shrink-0" />
					<div className="truncate">
						{getFormattedRange(job.min_hourly_rate, job.max_hourly_rate)} per hour
					</div>
				</Badge>
			)}
		</div>
	);
};
