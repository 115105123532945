import {cache} from 'react';
import {loadStripe} from '@stripe/stripe-js';

import type {Stripe} from '@stripe/stripe-js';

let stripePromise: Promise<Stripe | null> | null = null;
const stripe = () => {
	if (!stripePromise) {
		stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY!);
	}

	return stripePromise;
};

export const createStripe = cache(stripe);
