'use client';

import {fetchPrices, fetchSubscription} from '~/shared/api/subscription';
import {QUERY_KEYS} from '~/shared/constants/keys';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {useQuery} from '@tanstack/react-query';

export const useSubscription = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.Subscription,
		queryFn: async () => {
			const {data} = await fetchSubscription(supabase);
			return data;
		},
	});
};

export const usePrices = () => {
	const {supabase} = useSupabase();
	return useQuery({
		queryKey: QUERY_KEYS.Prices,
		queryFn: async () => {
			const {data} = await fetchPrices(supabase);
			return data;
		},
	});
};
