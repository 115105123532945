'use client';

import {useCallback, useMemo, useState} from 'react';
import {usePrices} from '~/shared/data/subscription';
import {createStripe} from '~/shared/services/stripe/client';
import {Pricing} from '@job-ish/blocks/components';
import {Button, Overlay} from '@job-ish/ui/components';
import {useToast} from '@job-ish/ui/hooks';
import {postData} from '@job-ish/utilities/data';

import type {Price} from '@job-ish/database/types';
import type {PropsWithChildren, ReactNode} from 'react';

export type SubscribeProps = PropsWithChildren<{
	action?: ReactNode;
}>;

export const Subscribe = ({action, children}: SubscribeProps) => {
	const {data: prices} = usePrices();
	const [subscribeLoading, setSubscribeLoading] = useState(false);
	const [billingInterval, setBillingInterval] = useState<'month' | 'year'>('month');

	const {show: showErrorToast} = useToast({
		accent: 'danger',
		accentPosition: 'left',
		title: 'Something went wrong',
		description: 'We were unable to process your request. Please try again later.',
		duration: 3000,
	});

	const handleCheckout = useCallback(
		async (price: Price) => {
			setSubscribeLoading(true);
			try {
				const res = await postData<{sessionId: string}>({
					url: '/api/checkout-session',
					data: {price},
				});

				if (!res) {
					throw new Error('Failed to create checkout session');
				}

				const stripe = await createStripe();
				stripe?.redirectToCheckout({sessionId: res.sessionId});
			} catch {
				showErrorToast();
			} finally {
				setSubscribeLoading(false);
			}
		},
		[showErrorToast],
	);

	const visiblePrice = useMemo(
		() => prices?.find(price => price.interval === billingInterval && price.active),
		[prices, billingInterval],
	);

	return (
		<div className="relative mx-auto flex w-full max-w-full grow flex-col overflow-y-auto sm:max-w-3xl sm:grow-0">
			{subscribeLoading && <Overlay className="absolute" loading />}
			<Pricing billingInterval={billingInterval} prices={prices} setBillingInterval={setBillingInterval}>
				{action ?? (
					<Button
						className="mt-4 w-full"
						color="primary"
						onPress={() => visiblePrice && handleCheckout(visiblePrice)}
					>
						Subscribe
					</Button>
				)}
				{children}
			</Pricing>
		</div>
	);
};
