import {PATHS} from '~/shared/constants/paths';
import {Button, CopyButton, TextButton} from '@job-ish/ui/components';
import {IconArrowBack, IconArrowLeft, IconBookmarkEdit, IconBookmarkPlus} from '@tabler/icons-react';
import {useRouter} from 'next/navigation';

import type {Job} from '@job-ish/database/types';
import type {JobRead} from '@job-ish/job-data-api/client/job-data-api';
import type {ReactNode} from 'react';

export type JobBoardListingActionsProps = {
	job?: JobRead;
	trackedJob?: Job;
	handleTrackJob?: () => void;
	showBackButton?: boolean;
	bookmarkIconOnly?: boolean;
	suffix?: ReactNode;
};

export const JobBoardListingActions = ({
	job,
	trackedJob,
	handleTrackJob,
	showBackButton,
	bookmarkIconOnly,
	suffix,
}: JobBoardListingActionsProps) => {
	const router = useRouter();

	return (
		<div className="flex w-full items-center justify-between gap-2">
			{showBackButton && (
				<>
					<TextButton
						as="div"
						className="flex lg:hidden"
						disabled={!job}
						iconLeft={IconArrowBack}
						onPress={() => router.push(PATHS.JobBoard)}
						size="sm"
					>
						Back to Board
					</TextButton>
					<TextButton
						as="div"
						className="hidden lg:flex"
						disabled={!job}
						iconLeft={IconArrowLeft}
						onPress={() => router.push(PATHS.JobBoard)}
						size="sm"
					>
						Collapse
					</TextButton>
				</>
			)}
			{(!bookmarkIconOnly || suffix) && (
				<div className="flex flex-grow items-start justify-end gap-3">
					{!bookmarkIconOnly && (
						<>
							<CopyButton
								className="hidden shrink-0 xs:flex"
								disabled={!job}
								intent="ghost"
								size="sm"
								text={`Check out this job I found: https://app.job-ish.com/job-board/${job?.id}`}
							>
								Share
							</CopyButton>
							<Button
								as="div"
								disabled={!job}
								iconRight={trackedJob ? IconBookmarkEdit : IconBookmarkPlus}
								intent="ghost"
								onPress={() => handleTrackJob?.()}
								size="sm"
							>
								{trackedJob ? 'Edit' : 'Track'}
							</Button>
						</>
					)}
					{suffix}
				</div>
			)}
		</div>
	);
};
