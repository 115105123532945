import {useCallback, useMemo} from 'react';
import type {SubscriptionDialogProps} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import {SubscriptionDialog} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import type {JobSheetProps} from '~/shared/components/sheets/job';
import {JobSheet} from '~/shared/components/sheets/job';
import {JOB_STATUS, type JobStatus} from '~/shared/constants/job';
import {useCompanies, useUpsertCompany} from '~/shared/data/company';
import {useUnfilteredJobs} from '~/shared/data/job';
import {useSubscription} from '~/shared/data/subscription';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {getJobType} from '~/shared/utils/job-board';
import {useModal} from '@job-ish/ui/hooks';
import dayjs from 'dayjs';

import type {JobRead} from '@job-ish/job-data-api/client/job-data-api';

export type UseTrackedJobParams = {
	job: JobRead;
};

export const useTrackedJob = ({job}: UseTrackedJobParams) => {
	const {mutateAsync: upsertCompany} = useUpsertCompany();
	const {show: showDialog} = useModal();
	const {data: jobs} = useUnfilteredJobs();
	const {data: companies} = useCompanies();
	const {user} = useSupabase();
	const {data: subscription} = useSubscription();

	const trackedJob = useMemo(
		() => jobs?.find(j => j.external_id === job.id || j.url === job.application_url),
		[jobs, job],
	);
	const existingCompany = useMemo(
		() => companies?.find(c => c.external_id === job.company.id),
		[companies, job],
	);

	const handleTrackJob = useCallback(
		async (options?: {status?: JobStatus; defaultTab?: string}) => {
			const {status = JOB_STATUS.ApplicationStarted, defaultTab} = options || {};

			if (!user || subscription?.status !== 'active') {
				showDialog<SubscriptionDialogProps>(SubscriptionDialog);
				return;
			}

			const company =
				trackedJob || existingCompany
					? existingCompany || trackedJob?.company
					: await upsertCompany({
							name: job.company.name,
							website: job.company.website_url,
							logo: job.company.logo,
							external_id: job.company.id,
						});

			const jobType = job.types.at(-1);

			showDialog<JobSheetProps>(
				JobSheet,
				trackedJob
					? {job: trackedJob}
					: {
							defaultTab,
							initialValues: {
								status,
								title: job.title,
								url: encodeURI(job.application_url),
								location: job.location,
								remote: job.has_remote ? 'true' : 'false',
								min_salary: job.salary_max ? Number(job.salary_min) : undefined,
								max_salary: job.salary_min ? Number(job.salary_max) : undefined,
								applied_at: status === JOB_STATUS.ApplicationSubmitted ? dayjs().toString() : undefined,
								company: {...company},
								type: jobType ? getJobType(jobType) : undefined,
								labels: [],
								job_description: job.description,
								currency: job.salary_currency,
								external_id: job.id,
							},
						},
			);
		},
		[existingCompany, job, showDialog, subscription?.status, trackedJob, upsertCompany, user],
	);

	return {
		handleTrackJob,
		trackedJob,
	};
};
